$('#client-table').on('click', '.client-delete', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#client-question').data('row', row);
    $('#client-question').data('id', id);
});
$('.client-delete-modal #modalSubmit').on('click', function(){
    var id = $('#client-question').data('id');
    var row = $('#client-question').data('row');
    axios.delete(route('client.delete', {client_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});