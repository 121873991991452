$('#team-leader-diary-table').on('click', '.team-leader-diary-delete', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#team-leader-diary-question').data('row', row);
    $('#team-leader-diary-question').data('id', id);
});
$('.team-leader-diary-delete-modal #modalSubmit').on('click', function(){
    var id = $('#team-leader-diary-question').data('id');
    var row = $('#team-leader-diary-question').data('row');
    axios.delete(route('team_leader_diary.delete', {team_leader_diary_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});