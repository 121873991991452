$('#shift-table').on('click', '.shift-delete', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#shift-question').data('row', row);
    $('#shift-question').data('id', id);
});
$('.shift-delete-modal #modalSubmit').on('click', function(){
    var id = $('#shift-question').data('id');
    var row = $('#shift-question').data('row');
    axios.delete(route('shift.delete', {shift_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});