const { each } = require('jquery');
window.JSZip = require('jszip');
require('datatables');
require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-buttons');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.flash.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive');
require('datatables.net-responsive-bs4');

$.extend(true, $.fn.dataTable.defaults, {
    "order": [[ 0, "asc" ]],
    "columnDefs": [ {
        "targets"  : 'no-sort',
        "orderable": false
    }],
    "processing": true
});

function applyFilters(table) {
    table.columns().every(function () {
        // switchFilter(this);
        switchFilter(this, true, table);
    });
    $('.filter').on('click', function(e){
        e.stopPropagation();
    });
}

function switchFilter(column, header = true, table = null) {
    let wrapper = header ? $(column.header()) : $(column.footer());
    let filter = wrapper.attr('data-filter');
    let input = wrapper.find('.filter');

    var timer = null;
    switch (filter) {
        case 'search':
            if (input.length !== 1) {
                let input = $('<input type="text" class="filter"/>')
                input.appendTo(wrapper)
                input.on('keyup', function () {
                    let val = $(this).val()
                    clearTimeout(timer);
                    timer = setTimeout(function() {
                        column.search( val, true, false).draw();
                    }, 700)
                });
            }
            break;
        case 'drop':
            if (input.length !== 1) {
                var select = $('<select class="filter" value=""><option value=""> -- </option></select>')
                    .appendTo(wrapper);
                column.data().unique().sort().each(function (d, j) {
                    select.append('<option value="'+d.toString().replace(/<[^>]*>?/gm, '')+'">'+d.toString().replace(/<[^>]*>?/gm, '')+'</option>');
                });
            }
            else {
                var select = input;
                let selected = input.val();
                select.find('option').remove();
                select.append('<option value="'+selected+'">'+selected+'</option>');
                select.append('<option value=""> -- </option>');
                column.data().unique().sort().each(function (d, j) {
                    select.append('<option value="'+d.toString().replace(/<[^>]*>?/gm, '')+'">'+d.toString().replace(/<[^>]*>?/gm, '')+'</option>');
                });
            }
            select.unbind('change');
            select.on('change', function () {
                let val = $.fn.dataTable.util.escapeRegex($(this).val());
                column.search(val ? '^'+val+'$' : '', true, false).draw();
            });
            break;
        case 'week':
            if (input.length !== 1) {
                let inputBlock = $('<input type="text" class="filter"/>');
                inputBlock.daterangepicker({
                    timePicker: false,
                    showWeekNumbers: true,
                    drops: "auto",
                    // autoApply: true,
                    locale: {
                        format: 'DD.MM.YYYY.',
                        firstDay: 1
                    },
                    maxSpan: {
                        days: 6
                    },
                    linkedCalendars: false,
                    alwaysShowCalendars: true,
                    ranges: {
                        'This week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                        'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek'), moment().subtract(1, 'isoWeek').endOf('isoWeek')],
                        '2 weeks ago': [moment().subtract(2, 'isoWeek').startOf('isoWeek'), moment().subtract(2, 'isoWeek').endOf('isoWeek')],
                        '3 weeks ago': [moment().subtract(3, 'isoWeek').startOf('isoWeek'), moment().subtract(3, 'isoWeek').endOf('isoWeek')],
                        '4 weeks ago': [moment().subtract(4, 'isoWeek').startOf('isoWeek'), moment().subtract(4, 'isoWeek').endOf('isoWeek')],
                    },
                }).appendTo(wrapper);
                inputBlock.on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    column.search( val ? val : '', true, false).draw();
                });
            }
            break;
        case 'date':
            if (input.length !== 1) {
                var inputBlock = $('<input type="text" class="filter" value=""/>');
                inputBlock.daterangepicker({
                    timePicker: false,
                    singleDatePicker: true,
                    drops: "auto",
                    autoApply: true,
                    locale: {
                        format: 'DD.MM.YYYY.',
                        firstDay: 1,
                        cancelLabel: 'Clear'
                    }
                }).appendTo(wrapper);
                inputBlock.unbind('change');
                inputBlock.on('change', function () {
                    var val = $(this).val(); //$.fn.dataTable.util.escapeRegex($(this).val());
                    column.search( val ? val : '', false, false).draw();
                });
            }
            break;
        case 'date-range':
            if (input.length !== 1) {
                var inputBlock = $('<input type="text" class="filter" value=""/>');
                inputBlock.daterangepicker({
                    timePicker: false,
                    drops: "auto",
                    autoApply: false,
                    locale: {
                        format: 'DD.MM.YYYY.',
                        firstDay: 1,
                        cancelLabel: 'Poništi'
                    }
                }).appendTo(wrapper).val('');
                inputBlock.unbind('change');
                inputBlock.on('apply.daterangepicker', function () {
                    let range = $(this).val().split(' - ');
                    minDateRange = range[0];
                    maxDateRange = range[1];
                    $.fn.dataTable.ext.search.push(function( settings, data, dataIndex, RowData ) {
                        if (data[column.index()] == '-') return false;
                        var api = new $.fn.dataTable.Api( settings );
                        let date = moment(data[column.index()], "DD.MM.YYYY.");
                        let min = moment(minDateRange, "DD.MM.YYYY.")
                        let max = moment(maxDateRange, "DD.MM.YYYY.");
                        if (date.isSameOrAfter(min) && date.isSameOrBefore(max)) {
                            return true;
                        }
                        return false;
                    })
                    console.log('apply');
                    column.draw();
                });
                inputBlock.on('cancel.daterangepicker', function () {
                    column.search('');
                });
            }
            break;
    }
}

function drawRow(table, settings) {
    var dbIndex = 0;
    var add_array = [];
    for (var i = 0, len = settings.array.length; i < len; i++) {
        add_array.push('');
    }
    add_array.push('<i class="bi bi-trash input-delete-row" style="color:red" data-toggle="tooltip" data-placement="top" title="Ukloni red"></i>');
    var rowNode = table.row.add(add_array).draw().node();
    var maxIndex = $(rowNode).children().length;

    $(rowNode).children().each(function(index) {
        var column_index = index + 1;
        var _input = _new = _what = _value = "";

        for (var i = 0, len = settings.array.length; i < len; i++) {
            if (typeof settings.array[i].input === "undefined") _input = ""; else _input = settings.array[i].input;
            if (typeof settings.array[i].value === "undefined") _value = ""; else _value = settings.array[i].value;
            if (typeof settings.array[i].type === "undefined") _type = 'input'; else _type = settings.array[i].type;
            if (typeof settings.array[i].options_index === "undefined") _options_index = false; else _options_index = settings.array[i].options_index;
            if (typeof settings.array[i].class === "undefined") _class = ''; else _class = ' '+settings.array[i].class+' '+settings.array[i].class+'-'+dbIndex;
            if (typeof settings.array[i].data === "undefined") _data = ''; else _data = ' data-'+settings.array[i].data;
            if (column_index === settings.array[i].index) {
                $(this).addClass('input-change');
                switch (_type) {
                    case 'hidden':
                        $(this).prepend('<input type="hidden" class="input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"/>');
                        break;
                    case 'input':
                        $(this).prepend('<input type="text" class="input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"/>');
                        break;
                    case 'select':
                        var select = $('<select class="drop-select input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"></select>');
                        for (var k in settings.array[i].options){
                            var active = '';
                            if (typeof settings.array[i].options[k] !== 'function') {
                                if (_value === settings.array[i].options[k]) active = ' selected';
                                var value_key = k;
                                if (_options_index) value_key = settings.array[i].options[k];
                                select.append( '<option value="'+value_key+'"'+active+'>'+settings.array[i].options[k]+'</option>' );
                            }
                        }
                        $(this).prepend(select);
                        break;
                    case 'number':
                        $(this).prepend('<input type="number" class="input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"/>');
                        break;
                }
            }
        }

        var column = table.columns([1]).every(function(){return this.data();});
        var select = $('<select class="drop-select" ></select>');
        var column_element = unique(column.data()[0].sort());
        $.each(column_element, function ( index, value ) {
            select.append( '<option value="'+value+'">'+value+'</option>' );
        });

        $(this).on('keyup change', function (e) {
            if ($(this).children('select').hasClass('drop-select')) {
                var text = $(this).children('select').val();
            } else {
                var text = $(this).html();
            }
            var regex = /(<([^>]+)>)/ig;
            $(this).prev().val(text.replace(regex, ""));
        });
        $('.input-delete-row').on( 'click', function (e) {
            e.preventDefault();
            table.row( $(this).parent().parent() ).remove().draw();
            $('.ui-tooltip ').remove();
        });

        dbIndex++;
    });

    $('.input-change').bind('input propertychange', function(e) {
        e.preventDefault();
        //var theClass = $(this).attr('class').split(' ')[1];
        var text = $(this).html();
        var regex = /(<([^>]+)>)/ig;
        //$('.'+theClass).val(text.replace(regex, ""));
        $(this).prev().val(text.replace(regex, ""));
    });
}

/**
 * Generate DataTables on <table> element
 * Options:
 * @param int num - if the tables have the same identifier, add a numeric value to the end (ex. #table-0 & $table-1)
 */
window.DropTableSearch = function(whatTable, settings = {}){
    var AddString = "";
    if (typeof settings.num !== "undefined") AddString = "-" + settings.num;

    var DTsettings = settings;

    if (typeof settings.lang !== "undefined" && settings.lang == 'hr') {
        DTsettings.language = {
            "sEmptyTable":      "Nema podataka u tablici",
            "sInfo":            "Prikazano _START_ do _END_ od _TOTAL_ rezultata",
            "sInfoEmpty":       "Prikazano 0 do 0 od 0 rezultata",
            "sInfoFiltered":    "(filtrirano iz _MAX_ ukupnih rezultata)",
            "sInfoPostFix":     "",
            "sInfoThousands":   ",",
            "sLengthMenu":      "Prikaži _MENU_ rezultata po stranici",
            "sLoadingRecords":  "Dohvaćanje...",
            "sProcessing":      "Dohvaćanje...",
            "sSearch":          "Pretraži:",
            "sZeroRecords":     "Nema pronađenih rezultata",
            "oPaginate": {
                "sFirst":       "Prva",
                "sPrevious":    "Nazad",
                "sNext":        "Naprijed",
                "sLast":        "Zadnja"
            },
            "oAria": {
                "sSortAscending":  ": aktiviraj za rastući poredak",
                "sSortDescending": ": aktiviraj za padajući poredak"
            }
        }
    }

    if (typeof settings.ajax_settings !== "undefined") {

        DTsettings = {
            ajax: settings.ajax_settings.ajax,
            columns: [
                settings.ajax_settings.columns
            ],
            // Per-row function to iterate cells
            createdRow: function (row, data, rowIndex) {
                // $(row).attr('data-value', data.attributes.value);
                // Per-cell function to do whatever needed with cells
                // $.each($('td', row), function (colIndex) {
                //     // For example, adding data-* attributes to the cell
                //     $(this).attr('data-foo', "bar");
                // });
            },
            //processing: true,
            //serverSide: true,
            info: true,
            paging: true,
            ordering: true,
            drawCallback: function( settings, json){
                $('.weigh-delete').click(function () {
                    var table = $(this).closest('table').DataTable({ "retrieve": true});
                    var row = $(this).closest('tr');

                    $.post(
                        window.host + "weighs/ajax/" + weigh_id + "/destroy",
                        {
                            _token: token,
                            material: material
                        }
                    ).done(function (response) {
                        table.row(row).remove().draw();
                        $('.ui-tooltip ').remove();
                    });
                });
            }
        };
    }

    DTsettings.buttons = [{
        extend: 'excel',
        text: 'Izvoz',
        exportOptions: {
            modifier: {
                // page: 'current'
            }
        }
    }];
    if (typeof settings.dom !== "undefined") {
        DTsettings.dom = 'Bfrtip';
    }

    DTsettings.responsive = true;
    DTsettings.columnDefs = [{
        targets: 'no-sort',
        orderable: false
    }];

    var table = $(whatTable+AddString).DataTable(DTsettings);

    // Draw buttons if any are defined
    if (typeof settings.buttons !== "undefined") {
        DTsettings.buttons = [{}];
        DTsettings.buttons[0].text = settings.buttons[0].text;
        //DTsettings.buttons[0].action = drawRow(table, settings);
    }

    // Filter settings
    //applyFilters(table);
    table.on('draw', function(){
        applyFilters(table);
    });

    return table;
};
