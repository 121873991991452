import './bootstrap';
import './general/functions';
import './general/admin-lte';
import './general/datetimepicker';
import './general/select2';
import './general/chart';
//import './general/inputmask';
//import './general/inputmask';
import './general/dataTables';
import './general/monthpicker';

//import './includes/events';
import './includes/user';
import './includes/location';
import './includes/calendar';
import './includes/shift';
import './includes/report';
import './includes/client';
import './includes/donation';
import './includes/diary';
import './includes/teamLeaderDiary';
import './includes/activity';
import './includes/superReport';
// Create MVC

// Events
// import './events/pusher';
// import './events/home'

$(function() {
	// We can attach the `fileselect` event to all file inputs on the page
	$(document).on('change', ':file', function() {
		var input = $(this),
			numFiles = input.get(0).files ? input.get(0).files.length : 1,
			label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
		input.trigger('fileselect', [numFiles, label]);
    });
    $(window).on('resize',function() {
        $('body > .wrapper').height($(window).height());
        $('body > .wrapper > .content-wrapper').height($(window).height() - $('.main-header').height() - $('.main-footer').height() - 34);
        $('.content').css('height', 'calc(100% - ' + ($('.content-header').height()+15) + 'px)');
    }).trigger('resize');
    $('#alert').delay(3000).hide(300);
    $('.treeview-menu li').each(function(){
        if ($(this).hasClass('active'))
            $(this).parent().parent().addClass('active');
    });
    $('.browse-button').on('click', function() {
        $('.browse-file').click();
    });
    $('.browse-input').on('click', function() {
        $('.browse-file').click();
    });
    $('.browse-file').on('fileselect', function(event, numFiles, label) {
        $('.browse-input').val(label);
    })
    $('.daterangepicker').daterangepicker({
        timePicker: false,
        locale: {
            format: 'DD.MM.YYYY.'
        }
    });
});

window.iChecInit = function() {
    $('input').on('ifChecked', function(event){
        $(this).val(1);
    });
    $('input').on('ifUnchecked', function(event){
        $(this).val(0);
    });
    $('input').iCheck({
        handle: 'checkbox',
        checkboxClass: 'icheckbox_square-blue',
        increaseArea: '20%'
    });
    $('input[type="checkbox"]').each(function(item){
        if($(this).val() == 1) $(this).iCheck('check');
        else $(this).iCheck('uncheck');
        $(this).on('change', function(){
            if($(this).val() == 1) $(this).iCheck('check');
            else $(this).iCheck('uncheck');
        });
    });

}
iChecInit();