$('#users-table').on('click', '.user-delete', function(){
  var row = $(this).parentsUntil('tr').parent();
  var id = $(this).data('id');
  $('#user-question').data('row', row);
  $('#user-question').data('id', id);
});
$('#users-table').on('click', '.user-delete', function(){
    var id = $('#user-question').data('id');
    var row = $('#user-question').data('row');
    axios.delete(route('user.delete', {user_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});

$('#contracts-table').on('click', '.payroll-delete', function(){
  var row = $(this).parentsUntil('tr').parent();
  var id = $(this).data('id');
  $('#payroll-question').data('row', row);
  $('#payroll-question').data('id', id);
});
$('.payroll-delete-modal #modalSubmit').on('click', function(){
  var id = $('#payroll-question').data('id');
  var row = $('#payroll-question').data('row');
  axios.delete(route('payroll.delete', {payroll_id:id}))
      .then(function (response) {
          notify(response.data.status);
          row.animate({
              //
            }, 300, function() {
              $(this).find('.exists').removeClass('exists');
              $(this).find('td:nth-child(3)').html('0,00 €');
              $(this).find('td:nth-child(4)').html('0,00 €');
              $(this).find('td:nth-child(5)').html('0,00 €');
            });
      })
      .catch(function (error) {
          console.log(error);
      });
});