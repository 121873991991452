$('#super-report-table').on('click', '.super-report-delete', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#super-report-question').data('row', row);
    $('#super-report-question').data('id', id);
});
$('.super-report-delete-modal #modalSubmit').on('click', function(){
    var id = $('#super-report-question').data('id');
    var row = $('#super-report-question').data('row');
    axios.delete(route('super_report.delete', {super_report_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});
$('#super-report-table').on('click', '.super-report-restore', function(){
    var id = $(this).data('id');
    var row = $(this).parentsUntil('tr').parent();
    axios.patch(route('super_report.restore', {super_report_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.removeClass('trashed');
        })
        .catch(function (error) {
            console.log(error);
        });
});