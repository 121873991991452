require('select2');

(function($) {
    $.fn.select2_alph = function(opt) {

        opt = $.extend({
            handle: "",
        }, opt);

        function permute(input, permArr, usedChars) {
            var i, ch;
            for (i = 0; i < input.length; i++) {
              ch = input.splice(i, 1)[0];
              usedChars.push(ch);
              if (input.length === 0) {
                permArr.push(usedChars.slice());
              }
              permute(input, permArr, usedChars);
              input.splice(i, 0, ch);
              usedChars.pop();
            }
            return permArr;
        }

        if (this.length == 0) return this;

        this.each(function(){
            $(this).select2({
                matcher: function(term, text) {
                    if (term.length === 0) return true;
                    texts = text.split(" ");
                    allCombinations = permute(texts, [], []);
                    for (i in allCombinations) {
                    if (allCombinations[i].join(" ").toUpperCase().indexOf(term.toUpperCase()) === 0) {
                        return true;
                    }
                    }
                    return false;
                }
            });
        });

        return this;

    };

    $.fn.select2_custom = function() {
        this.each(function(){
            $(this).select2({
                tags: true
            });
        });
        return this;
    };

    $.fn.select2_simple = function() {
        this.each(function(){
            $(this).select2({
                minimumResultsForSearch: Infinity
            });
        });
        return this;
    };

    $.fn.select2_extra_filter = function() {
        this.each(function(){
            $(this).select2({
                matcher: function(params, data) {
                    if ($.trim(params.term) === '') { return data; }
                    if (typeof data.text === 'undefined' || typeof data.element.dataset.filter === 'undefined') { return null; }
                    var searchTarget = params.term.toLowerCase();
                    if (data.text.toLowerCase().indexOf(searchTarget) > -1 || data.element.dataset.filter.toLowerCase().indexOf(searchTarget) > -1) {
                        return $.extend({}, data, true);
                    }
                    return null;
                }
            });
        });
        return this;
    };
})(jQuery);

$('.drop-select').select2();
$('.drop-select').select2_custom();
$(".drop-select-alph").select2_alph();
$('.drop-select-simple').select2_simple();
$('.drop-select-extra-filter').select2_extra_filter();