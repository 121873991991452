$('#donation-table').on('click', '.donation-delete', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#donation-question').data('row', row);
    $('#donation-question').data('id', id);
});
$('.donation-delete-modal #modalSubmit').on('click', function(){
    var id = $('#donation-question').data('id');
    var row = $('#donation-question').data('row');
    axios.delete(route('donation.delete', {donation_id:id}))
        .then(function (response) {
            notify(response.data.status);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});