$('#report-table').on('click', '.report-delete', function(){
    var row = $(this).parentsUntil('tr').parent();
    var id = $(this).data('id');
    $('#report-question').data('row', row);
    $('#report-question').data('id', id);
});
$('.report-delete-modal #modalSubmit').on('click', function(){
    var id = $('#report-question').data('id');
    var row = $('#report-question').data('row');
    axios.delete(route('report.delete', {report_id:id}))
        .then(function (response) {
            notify(response.data.status);
            $('#hours_remaining span').html(response.data.hours_remaining);
            row.addClass('overflow-hidden').animate({
                opacity: 0,
                height: 0
              }, 300, function() {
                $(this).remove();
              });
        })
        .catch(function (error) {
            console.log(error);
        });
});