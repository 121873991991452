require('bootstrap-daterangepicker');

jQuery.datetimepicker.setLocale('en');

$('.datepicker-plus').datetimepicker({
    timepicker:false,
    closeOnDateSelect:true,
    format:'d.m.Y',
    dayOfWeekStart: 1,
    scrollMonth: false,
    timepickerScrollbar: false,
    // minDate:'-1970/01/02', //yesterday is minimum date(for today use 0 or -1970/01/01)
    // maxDate:'+1970/01/02', //tomorrow is maximum date calendar
});

$('.timepicker').datetimepicker({
    datepicker:false,
    defaultTime:'8:00',
    minTime:'06:00',
    maxTime:'22:01',
    format:'H:i',
    step:30,
});