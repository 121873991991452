var Chart = require('chart.js');
require('chartjs-plugin-datalabels');

window.ChartJs = function(selector, settings) {
    var defaults = {
        options: {
            title: {
                display: true,
                fontSize: 16,
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: false
                    }
                }]
            },
            plugins: {
                datalabels: {
                    display: false,
                    color: '#36A2EB'
                }
            }
        }
    }

    var cjs = new Chart($(selector), {...settings, ...defaults });

    return cjs;
}